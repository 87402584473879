.pr_wrapper {
    display:flex;
    flex-direction: column;
    min-height:100vh;
    width:100%;
    margin:0;
}
.pr_page_header {
    min-height:200px;
    background-size: auto 100%;
    background-image: url('../images/cropped-banner3-1.jpg');
    background-repeat: no-repeat;
    background-position: center;
}
@media screen and (min-width: 750px) {
    .pr_page_header {
        background-size: 100% auto;
        background-position: bottom;
    }

}
@media screen and (min-width: 950px) {
    .pr_page_header {
        min-height:250px;
    }
}

.pr_nav_wrapper {
    display:flex;
    flex-direction:row;
    justify-content: center;
    width:100%;
    background:#421414;
}
.pr_menu {
    background: #421414;
    color: #FFF;
    height: 50px;
    padding-left: 18px;
    border-radius: 0px;
    position:relative;
}
.pr_menu ul, .pr_menu li {
    margin: 0 auto;
    padding: 0;
    list-style: none
}
.pr_menu ul {
    width: 100%;
}
.pr_menu li {
    float: left;
    display: inline;
    position: relative;
}
.pr_menu a {
    display: block;
    line-height: 50px;
    padding: 0 14px;
    text-decoration: none;
    color: #FFFFFF;
    font-size: 16px;
    text-transform: uppercase;
}
.pr_menu a.dropdown_arrow:after {
    content: "\25BE";
    margin-left: 5px;
}
.pr_menu li a:hover {
    color: #421414;
    background: #F2F2F2;
}
.pr_menu input {
    display: none;
    margin: 0;
    padding: 0;
    height: 50px;
    width: 100%;
    opacity: 0;
    cursor: pointer
}
.pr_menu label {
    display: none;
    line-height: 50px;
    text-align: center;
    position: absolute;
    left: 35px
}
.pr_menu label:before {
    font-size: 1.6em;
    content: "\2261";
    margin-left: 20px;
}
.pr_menu ul.sub_menus{
    height: auto;
    overflow: hidden;
    width: 250px;
    background: #444444;
    position: absolute;
    z-index: 99;
    display: none;
}
.pr_menu ul.sub_menus li {
    display: block;
    width: 100%;
}
.pr_menu ul.sub_menus a {
    color: #FFFFFF;
    font-size: 12px;
}
.pr_menu li:hover ul.sub_menus {
    display: block
}
.pr_menu ul.sub_menus a:hover{
    background: #F2F2F2;
    color: #444444;
}
@media screen and (max-width: 800px){
    .pr_menu {display:block;padding-left:0px;height:unset;}
    .pr_menu ul {background:#421414;display:block}
    .pr_menu ul.sub_menus {width:100%;position:unset;}
    .pr_menu ul.sub_menus a {padding-left:30px;}
    .pr_menu li {display:block;float:none;width:auto;}
    .pr_menu input, #menu label {position:absolute;top:0;left:0;display:block}
    .pr_menu input {z-index:4}
    .pr_menu input:checked + label {color:white}
    .pr_menu input:checked + label:before {content:"\00d7"}
    .pr_menu input:checked ~ ul {display:block}
}




















































.pr_header_row_1 {
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    padding:20px;
}
.pr_top_menu {
    display:flex;
    flex-direction:column;
    justify-content: center;
    background: rgb(66, 20, 20);
    min-height:40px;
    font-size:14pt;
    margin:0;
}
@media screen and (min-width:600px) {
    .pr_top_menu {
        flex-direction:row;
        flex-wrap:nowrap;
    }
}
.pr_top_menu_item {
    padding: 0 20px;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    position:relative;
}
.pr_top_menu_item:hover {
    background:black;
}
.pr_top_menu_item a {
    text-decoration:none;
    text-transform:uppercase;
    color: #c5c5c5;

}
.pr_dropdown_element {
    position:relative;
    top:40px;
    width:200px;
    background: rgb(66, 20, 20);
    padding:15px;
    box-shadow: 2px 2px white;
}
.pr_contact_button {
    border:1px solid black;
    padding:10px;
    cursor:pointer;
    height:40px;
}
.pr_contact_button:hover {
    background: #b9b9b9;
}
@media screen and (min-width: 550px) {
    .pr_page_header ul {
        width:auto;
        margin-top:0;
    }
    .pr_contact_button {
    }
}
.pr_main_container {
    flex-grow:1;
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    background: url('../images/hay.jpg') no-repeat center / cover;
}
.pr_main_column {
    flex-grow:1;
    min-width:300px;
    min-height:400px;
    max-width:1000px;
    width:1000px;
    background: rgba(255, 255, 255, 0.8);
}
.pr_outlet_holder {
    padding:20px;
}
.pr_left_column {
    min-width:100px;
    width:200px;
    padding:20px;
}
.pr_right_column {
    min-width:200px;
    width:300px;
    padding:20px;
}
.pr_footer {
    padding:20px;
    display:flex;
    flex-direction:column-reverse;
}
.pr_footer ul {
    display:flex;
    font-size: 1.5rem;
    margin-bottom:5px;
}
@media screen and (min-width: 550px) {
    .pr_footer {
        flex-direction:row;
        justify-content: space-between;
        align-items:center;
    }
    .pr_footer ul {
        margin-bottom:0;
    }
}
.pr_edit_cancel_button_row {
    display:flex;
    flex-direction:row;
    justify-content: end;
    padding:20px;
}
.pr_directory_edit_button {
    cursor:pointer;
    font-size:20pt;
    width:100px;
}
.pr_directory_delete_button{
    cursor:pointer;
    font-size:20pt;
    width:120px;
    margin-right:3px;
}
.pr_directory_cancel_button {
    cursor:pointer;
    font-size:20pt;
    width:130px;
}

.pr_family_row {
    background: #eaeaea;
    margin:5px;
    padding:10px;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    cursor:pointer;
}
.pr_family_data_row {
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
}
.pr_family_row:hover {
    background: #dedede;
}
.pr_family_name {
    font-size:18pt;
    min-width:150px;
}
.pr_address_div {
    min-width:250px;
    margin: 2px 10px;
}
.pr_more_info_div {
    min-width:250px;
    margin: 2px 10px;
}
.pr_hide {
    display:none;
}
.pr_hidden_details_row {
    width:600px;
    padding:10px;
}
.pr_family_profile_pic {
    width:100%;
}
.pr_person_basic_row {
    margin-bottom:15px;
}
.pr_bold {
    font-weight:bold;
}
.birth_anniversary_div {
    margin:20px;
}
.pr_top_logo {
    width:200px;
}
@media screen and (min-width: 550px) {
    .pr_top_logo {
        width:300px;
    }
}
.pr_heading_medium {
    font-size:18pt;
    margin-top:20px;
}
.pr_block_quote {
    margin:10px 30px 10px 30px;
}
.pr_spacing_one_and_a_half {
    line-height:1.7em;
}
.pr_center {
    display:flex;
    justify-content:center;
}
.pr_font_18 {
    font-size:18pt;
}
.pr_font_16 {
    font-size:16pt;
}
.pr_font_14 {
    font-size:14pt;
}
.pr_padding_20 {
    padding:20px;
}
.pr_video {
    width:100%;
    aspect-ratio: 16 / 9;
}
.pr_sermon_block {
    padding:10px;
    background: #eeeeee;
    margin-bottom:10px;
}
.pr_sermon_table_row {
    background: #eeeeee;
}
.pr_sermon_table_row td {
    padding:10px;
    vertical-align: top;
}
.pr_heading_large {
    margin-top:50px;
    font-size:36pt;
}
.pr_embed_video_holder {
    display:flex;
    flex-direction:row;
    justify-content:center;
}
.pr_slideshow_holder {
    margin-left:auto;
    margin-right:auto;
    max-width:700px;
    position:relative;
}
.pr_slideshow_holder:hover {
    display: block;
}

.pr_slideshow_controls {
    width:100%;
    text-align:center;
    position:relative;
    left:50%;
    transform:translate(-50%,0%);
    -ms-transform:translate(-50%,0%);
}

.pr_slideshow_button {
    background-color:unset;
    color:#fff;
    display:inline-block;
    padding-left:8px;
    padding-right:8px;
    margin: 8px 2px;
    text-align:center;
    border-radius:50%;
    border:1px solid #737373;
    height:16px;
}
.pr_slideshow_button_selected {
    background-color: #444444;
}
.pr_slideshow_left {
    float:left;
    margin:10px;
    cursor:pointer;
}
.pr_slideshow_right {
    float:right;
    margin:10px;
    cursor:pointer;
}

.pr_slideshow_image {
    display:none;
    width:100%;
}
.pr_slideshow_image {
    animation:fading 10s infinite
}@keyframes fading{0%{opacity:0}10%{opacity:1}90%{opacity:1}100%{opacity:0}}

.pr_home_wrapper {
    display:flex;
    flex-direction:column;
    align-items:center;
}
.pr_home_info_row {
    display:flex;
    flex-direction:row;
    flex-wrap: wrap-reverse;
    justify-content:center;
}
.pr_home_info_text {
    flex:.5;
    font-size:30pt;
    margin:20px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    text-align:center;
    font-weight: lighter;
}
.pr_home_info_image {
    flex:.5;
    max-width:300px;
    margin:20px;
}
.pr_login_div {
    padding:10px;
    display:flex;
    flex-direction:column;
    justify-content: center;
    vertical-align: middle;
}
@media screen and (min-width:600px) {
    .pr_login_div {
        flex-direction:row;
        flex-wrap:wrap;
    }
}
.pr_login_error {
    display:flex;
    flex-direction: row;
    justify-content: center;
    color:red;
    font-style: italic;
    padding: 0 0 10px 0;
}
.pr_login_div div {
    margin: 5px 10px;
    display:flex;
    justify-content: center;
    flex-direction: column;
}
.pr_login_input {
    padding: 5px 15px;
    border-radius: 8px;
    border: 1px solid #333;
    width:200px;
    background:white;
    /*transition: all 0.3s ease-in-out;*/
}
.pr_login_input:focus {
    /* border-color: #421414; */
    border:none;
    outline:none;
    margin:1px;
    box-shadow: 0 0 10px #421414;
    background-color:white;
}
.pr_button {
    border:1px solid black;
    max-width:50px;
}
.pr_button:hover {
    background: #b9b9b9;
    cursor:pointer;
}
.pr_button:active {
    background: #989898;
}
.pr_welcome {
    padding: 0 10px;
    text-shadow:1px 1px 2px white;
}
.pr_edit_text_field {
    font-size:14pt;
    width:200px;
}
.pr_edit_family_form {
    font-size:14pt;
}
.pr_edit_family_form div label {
    display:flex;
    flex-direction:row;
    justify-content: end;
    margin-right:20px;
}
.pr_edit_family_form input {
    font-size:14pt;
    margin-left:10px;
    width:300px;
}
.pr_edit_person_div {
    font-size:12pt;
    max-width:500px;
}
.pr_edit_person_div label {
    display:flex;
    flex-direction:row;
    justify-content: end;
}
.pr_edit_person_div input {
    margin-left:10px;
    width:300px;
}
.pr_edit_person_button_row {
    display:flex;
    flex-direction:row;
    justify-content: end;
    padding:10px;
}
.pr_membership_status_selector {
    width:308px;
    margin:0 0 3px 10px;
    height:24px;
}
.pr_checkbox {
    height: 24px;
}
.pr_add_person_button {
    border:1px solid black;
    cursor:pointer;
    padding:15px;
}
.pr_add_person_button:hover {
    background: #b7b7b7;
}
.pr_add_person_form {
    display:flex;
    flex-direction:column;
    max-width:500px;
}
.pr_add_person_form_row {
    display:flex;
    flex-direction:row;
    justify-content:end;
    align-items:center;
}
.pr_add_person_form_row input {
    width:300px;
    font-size:12pt;
    margin:0 0 3px 10px;
}
.pr_create_family_button {
    border:1px solid black;
    cursor:pointer;
    padding:4px 5px 3px 5px;
    margin-left:5px;
    width:125px;
}

.pr_create_family_button:hover {
    background: #b7b7b7;
}
.pr_family_selector {
    width:178px;
    margin:0 0 3px 10px;
    height:24px;
}
.pr_modal {
    z-index: 3;
    display: none;
    padding-top: 100px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4)
}
.pr_modal_visible {
    z-index: 3;
    display: block;
    padding-top: 100px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4)
}
.pr_modal_header {
    width:100%;
    height:40px;
    background:#421414;
    color:white;
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:14pt;
}
.pr_modal_body {
    padding:20px;
}
.pr_modal_footer {
    background:#30320B;
    display:flex;
    height:40px;
    color:white;
    align-items:center;
    justify-content: end;
    padding: 0 10px 0 10px;
}
.pr_modal_footer button {
    margin-left:3px;
    padding:3px;
}

.pr_modal_content {
    display:flex;
    flex-direction:column;

    margin: auto;
    background-color: #fff;
    position: relative;
    padding: 0;
    outline: 0;
    width: 600px
}
.pr_print_family {
    page-break-after:always;
    display:flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
    height: 950px;
    position:relative;
}
.pr_print_family_name {
    font-size:20pt;
    background:black;
    color:white;
    padding:4px;
    text-align:center;
    font-weight:bold;
    width:100%;
    margin:15px;
}
.pr_print_photo {
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    max-height: 864px;
    overflow: hidden;
}
.pr_print_info_div {
    background: white;
    display:flex;
    flex-direction:column;
    justify-content: stretch;
    width: 560px;
    height:250px;
    border:1px solid black;
    bottom:30px;
    position:absolute;
    opacity:0.82;
    padding:20px;
    font-size:20pt;
}
.pr_print_heads_of_household {
    display:flex;
    flex-direction:row;
    justify-content: space-evenly;
    width:100%;
}
.pr_print_head_of_household {
    width:100%;
}
.pr_print_spouse {
    width: 100%;
}
.pr_print_subinfo {
    font-size:14pt;
    margin-left:20px;
}
.pr_print_data_note {
    font-size:12pt;
    font-style: italic;
}
.pr_print_fam_and_children_row {
    display: flex;
    flex-direction: row;
}
.pr_print_family_info {
    display: flex;
    justify-content: center;
    font-size:17pt;
    margin-top:10px;
    flex-direction: column;
    margin-left:10px;
}
.pr_print_fam_info_item {
    margin-left: 30px;
}
.pr_print_address {
    padding:3px 10px 3px 3px;
    background: #e1e1e1;
}
.pr_print_extra_family_info {
    margin-top:10px;
    font-size: 15pt;
}
.pr_print_children {
    padding:10px;
    margin-left:20px;
    margin-top: 20px;
    font-size: 12pt;
}
.testimony_div {
    padding:5px;
    font-size:14pt;
    margin-bottom:10px;
}
.pr_vid_logos {
    height:25px;
}

.pr_home_page_alert {
    color:red;
    font-size:18pt;
}






.pr_insert_sermon_form {
    display: flex;
    flex-direction: column;
    max-width: 600px; /* Adjust as needed */
    margin: auto; /* Center the form on the page */
}

.pr_form_group {
    display: flex;
    justify-content: space-between; /* Align label and input */
    margin-bottom: 15px; /* Spacing between fields */
}

.pr_insert_sermon_label {
    flex: 0 0 150px; /* Set a fixed width for labels */
    text-align: right; /* Right justify labels */
    margin-right: 10px; /* Space between label and input */
}

.pr_sermon_input,
.pr_sermon_date_input,
.pr_sermon_textarea {
    flex: 1; /* Allow inputs to take up remaining space */
    padding: 8px; /* Add some padding */
    border: 1px solid #ccc; /* Basic border */
    border-radius: 4px; /* Rounded corners */
}

.pr_sermon_textarea {
    resize: none; /* Disable resizing */
}

.pr_sermon_button {
    margin-top: 10px; /* Space above button */
    padding: 10px 15px; /* Button padding */
    background-color: #007BFF; /* Button background color */
    color: white; /* Button text color */
    border: none; /* Remove border */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
}

.pr_sermon_button:hover {
    background-color: #0056b3; /* Darker background on hover */
}